import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';

import { Page } from 'Component/Header/Header.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductListContainer as SourceProductListContainer,
} from 'SourceComponent/ProductList/ProductList.container';
import { scrollToTop } from 'Util/Browser';
import { getLocalStorageItemToken } from 'Util/Common/Util';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';
import { RootState } from 'Util/Store/Store.type';
import { setQueryParams } from 'Util/Url';

import { ProductListContainerProps } from './ProductList.type';
/** @namespace Pwa/Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Component/ProductList/Container */
export class ProductListContainer extends SourceProductListContainer {
    _getIsInfiniteLoaderEnabled(): boolean { // disable infinite scroll on mobile
        // const { isInfiniteLoaderEnabled = false, device } = this.props;
        // const {
        // isInfiniteLoaderEnabled: defaultIsInfiniteLoaderEnabled = false,
        // } = ProductListContainer.defaultProps;

        // allow scroll and mobile
        // if (device.isMobile) {
        //     return isInfiniteLoaderEnabled || defaultIsInfiniteLoaderEnabled;
        // }

        return false;
    }

    componentDidUpdate(prevProps: ProductListContainerProps): void {
        const {
            sort,
            search,
            filter,
            pages,
            isPlp,
        } = this.props;

        const {
            sort: prevSort,
            search: prevSearch,
            filter: prevFilter,
            location: prevLocation,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();
        const isProductListUpdated = JSON.stringify(filter) !== JSON.stringify(prevFilter)
                                    || currentPage !== prevPage;

        const isSortUpdated = JSON.stringify(sort) !== JSON.stringify(prevSort);

        if (isProductListUpdated || isSortUpdated) {
            window.isPrefetchValueUsed = false;
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        if (this._getIsInfiniteLoaderEnabled() && isPlp && !(isProductListUpdated || isSortUpdated)) {
            return;
        }

        if (search !== prevSearch || isProductListUpdated) {
            this.requestPage(this._getPageFromUrl());
        }

        if (isSortUpdated) {
            const isOnlySortUpdated = true;
            this.requestPage(this._getPageFromUrl(), false, isOnlySortUpdated);
        }
    }

    requestPage(currentPage = 1, isNext = false, isOnlySortUpdated = false): void {
        const {
            sort,
            search,
            filter,
            pageSize,
            requestProductList,
            requestProductListInfo,
            noAttributes,
            noVariants,
            isWidget,
            device,
            location: { pathname },
        } = this.props;
        const { isPrefetchValueUsed } = window;

        const isSearch = pathname.includes(Page.SEARCH);
        const isPrefetched = isPrefetchValueUsed && !isWidget && !isSearch;

        /**
         * In case the wrong category was passed down to the product list,
         * prevent it from being requested.
         */
        if (filter.categoryIds === -1) {
            return;
        }

        /**
         * Do not request page if there are no filters
         */
        if (!search && !this.isEmptyFilter()) {
            return;
        }

        // TODO: product list requests filters alongside the page
        // TODO: sometimes product list is requested more then once
        // TODO: the product list should not request itself, when coming from PDP

        const isMphUser = getLocalStorageItemToken('is_mph');

        const options = {
            isNext,
            noAttributes,
            noVariants,
            args: {
                sort: sort ?? undefined,
                filter: {
                    ...filter,
                    customFiltersIsMph: isMphUser,
                },
                search,
                pageSize,
                currentPage,
            },
        };

        const infoOptions = {
            args: {
                filter: {
                    ...filter,
                    customFiltersIsMph: isMphUser,
                },
                search,
            },
        };

        if (!isPrefetched) {
            requestProductList(options);
        }

        if (!isWidget) {
            if (!isOnlySortUpdated) {
                waitForPriorityLoad().then(
                /** @namespace Pwa/Component/ProductList/Container/ProductListContainer/requestPage/waitForPriorityLoad/then/requestProductListInfo */
                    () => requestProductListInfo(infoOptions),
                );
            }

            if (!device.isMobile) {
                scrollToTop();
            }
        }
    }

    updatePage(pageNumber: number): void {
        const { location, history } = this.props;

        setQueryParams({
            page: pageNumber === 1 ? '' : String(pageNumber),
        }, location, history);

        if (!this._getIsInfiniteLoaderEnabled()) {
            scrollToTop();
        }
    }
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer as unknown as React.ComponentType<
        RouteComponentProps & ProductListContainerProps
        >,
    ),
);
