import { ReactElement, Suspense } from 'react';

import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    AddToCart as SourceAddToCart,
    FieldContainer as SourceFieldContainer,
    GroupedProductList as SourceGroupedProductList,
    ProductBundleOptions as SourceProductBundleOptions,
    ProductCompareButton as SourceProductCompareButton,
    ProductComponent as SourceProductComponent,
    ProductConfigurableAttributes as SourceProductConfigurableAttributes,
    ProductCustomizableOptions as SourceProductCustomizableOptions,
    ProductDownloadableLinks as SourceProductDownloadableLinks,
    ProductDownloadableSamples as SourceProductDownloadableSamples,
    ProductReviewRating as SourceProductReviewRating,
    ProductWishlistButton as SourceProductWishlistButton,
} from 'SourceComponent/Product/Product.component';

// TODO: implement ProductReviewRating
export const ProductReviewRating = SourceProductReviewRating;

// TODO: implement ProductConfigurableAttributes
export const ProductConfigurableAttributes = SourceProductConfigurableAttributes;

// TODO: implement AddToCart
export const AddToCart = SourceAddToCart;

// TODO: implement FieldContainer
export const FieldContainer = SourceFieldContainer;

// TODO: implement ProductCustomizableOptions
export const ProductCustomizableOptions = SourceProductCustomizableOptions;

// TODO: implement ProductBundleOptions
export const ProductBundleOptions = SourceProductBundleOptions;

// TODO: implement GroupedProductList
export const GroupedProductList = SourceGroupedProductList;

// TODO: implement ProductCompareButton
export const ProductCompareButton = SourceProductCompareButton;

// TODO: implement ProductDownloadableLinks
export const ProductDownloadableLinks = SourceProductDownloadableLinks;

// TODO: implement ProductDownloadableSamples
export const ProductDownloadableSamples = SourceProductDownloadableSamples;

// TODO: implement ProductWishlistButton
export const ProductWishlistButton = SourceProductWishlistButton;

/** @namespace Pwa/Component/Product/Component */
export class ProductComponent extends SourceProductComponent {
    renderAddToCartButton(layout = CategoryPageLayout.GRID): ReactElement {
        const {
            addToCart,
            inStock,
            quantity,
            getActiveProduct,
            updateSelectedValues,
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled={ false }
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ quantity }
                  product={ getActiveProduct() }
                />
            </Suspense>
        );
    }
}

export default ProductComponent;
