const base = '/search/%20?customFilters=family:';

export const baseImageUrl: string = '/media/wysiwyg/';

export const marchiTop = [
    {
        url: `${base}794245`,
        urlProd: `${base}77`,
        image: `${baseImageUrl}topperformances.png`,
        alt: 'topperformances',
    },
    {
        url: `${base}794206`,
        urlProd: `${base}38`,
        image: `${baseImageUrl}racingparts.png`,
        alt: 'racingparts',
    },
    {
        url: `${base}794248`,
        urlProd: `${base}80`,
        image: `${baseImageUrl}tprfactory.png`,
        alt: 'tprfactory',
    },
    {
        url: `${base}794221`,
        urlProd: `${base}53`,
        image: `${baseImageUrl}mph.png`,
        alt: 'mph',
    },
    {
        url: `${base}794221`,
        urlProd: `${base}53`,
        image: `${baseImageUrl}emotion.png`,
        alt: 'emotion',
    },
];

/** logo bottom */
export const marchiBottom = [
    {
        url: `${base}794266`,
        urlProd: `${base}98`,
        image: `${baseImageUrl}leovince.png`,
        alt: 'leovince',
    },
    {
        url: `${base}794263`,
        urlProd: 'search/HIFLO',
        image: `${baseImageUrl}hiflofiltro.png`,
        alt: 'hiflofiltro',
    },
    {
        url: `${base}794251`,
        urlProd: `${base}83`,
        image: `${baseImageUrl}trw.png`,
        alt: 'trw',
    },
    {
        url: `${base}794236`,
        urlProd: `${base}68`,
        image: `${baseImageUrl}sachs.png`,
        alt: 'sachs',
    },
    {
        url: `${base}794212`,
        urlProd: '/search/LAMPADINE%20OSRAM',
        image: `${baseImageUrl}osram.png`,
        alt: 'osram',
    },
    {
        url: `${base}794224`,
        urlProd: `${base}56`,
        image: `${baseImageUrl}ngk.png`,
        alt: 'ngk',
    },
    {
        url: `${base}794212`,
        urlProd: '/search/LAMPADINE%20TOPLIGHT',
        image: `${baseImageUrl}toplight.png`,
        alt: 'toplight',
    },
    {
        url: `${base}794233`,
        urlProd: `${base}65`,
        image: `${baseImageUrl}reckless.png`,
        alt: 'reckless',
    },
    {
        url: `${base}794242`,
        urlProd: '/search/%20?customFilters=sub_group:3815',
        image: `${baseImageUrl}ntn.png`,
        alt: 'ntn',
    },
    {
        url: `${base}794230`,
        urlProd: '/search/PRODOTTI%20PER%20PASSIONE%20XFORTE',
        image: `${baseImageUrl}xforte.png`,
        alt: 'xforte',
    },
    {
        url: `${base}794140`,
        urlProd: '/search/noco',
        image: `${baseImageUrl}noco.png`,
        alt: 'noco',
    },
    {
        url: `${base}794185`,
        urlProd: `${base}119`,
        image: `${baseImageUrl}tecneco.png`,
        alt: 'tecneco',
    },
    {
        url: `${base}794173`,
        urlProd: `${base}107`,
        image: `${baseImageUrl}arexons.png`,
        alt: 'arexons',
    },
    {
        url: `${base}794170`,
        urlProd: `${base}104`,
        image: `${baseImageUrl}beru.png`,
        alt: 'beru',
    },
    {
        url: '/search/petronas',
        urlProd: '/search/petronas',
        image: `${baseImageUrl}petronas.png`,
        alt: 'petronas',
    },
    {
        url: '/search/exide',
        urlProd: '/search/exide',
        image: `${baseImageUrl}exide.png`,
        alt: 'exide',
    },
    {
        url: '/search/beta',
        urlProd: '/search/beta',
        image: `${baseImageUrl}beta.png`,
        alt: 'beta',
    },
    {
        url: '/search/snr',
        urlProd: '/search/snr',
        image: `${baseImageUrl}snr.png`,
        alt: 'snr',
    },
    {
        url: '/search/centauro',
        urlProd: '/search/centauro',
        image: `${baseImageUrl}centauro.png`,
        alt: 'centauro',
    },
];
