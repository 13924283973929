// @ts-nocheck
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
    ProductAttributeFilterOptions,
} from 'Query/ProductList.type';
import {
    CategoryPageContainer as SourceCategoryPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/CategoryPage/CategoryPage.container';
import { getLocalStorageItemToken } from 'Util/Common/Util';
import { RootState } from 'Util/Store/Store.type';
/** @namespace Pwa/Route/CategoryPage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Route/CategoryPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Route/CategoryPage/Container */
export class CategoryPageContainer extends SourceCategoryPageContainer {
    getFilter(): ProductAttributeFilterOptions {
        const { categoryIds } = this.props;
        const customFilters = this.getSelectedFiltersFromUrl();
        const priceRange = this.getSelectedPriceRangeFromUrl();

        const isMphUser = getLocalStorageItemToken('is_mph');

        if (categoryIds === -1) {
            return {
                priceRange,
                customFilters,
            };
        }

        return {
            priceRange,
            customFilters,
            categoryIds,
            customFiltersIsMph: isMphUser,
        };
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(
    CategoryPageContainer,
);
