/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-elements */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import CmsBlock from '@scandipwa/scandipwa/src/component/CmsBlock';
import { PureComponent, ReactElement } from 'react';
import {
    A11y, Navigation, Pagination, Scrollbar,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { getCurrentEnv, getUrlToSearch } from 'Util/Common/Util';

import {
    baseImageUrl,
    marchiBottom,
    marchiTop,
} from './StaticHomePage.config';
import { StaticHomePageComponentProps } from './StaticHomePage.type';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './StaticHomePage.style';

/** @namespace Pwa/Component/StaticHomePage/Component */
export class StaticHomePageComponent extends PureComponent<StaticHomePageComponentProps> {
    currentEnv = getCurrentEnv();

    blockHomepageTop(): ReactElement {
        return (
            <div block="StaticHomePage" elem="ContainerBTop">
                <div block="StaticHomePage" elem="LeftSection">
                    <img src={ `${baseImageUrl}moto.png` } alt="moto" />
                    <div block="StaticHomePage" elem="Overlay">
                        <img src={ `${baseImageUrl}flag.png` } alt="Brand Logo" />
                        <h2>{ __('BlockHpTop.title') }</h2>
                        <p>{ __('BlockHpTop.desc') }</p>
                        <a href="/search/%20" rel="noopener noreferrer">
                            <button block="StaticHomePage" elem="Button">{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
                <div block="StaticHomePage" elem="RightSection">
                    <div>
                        <h3>{ __('BlockHpTop.ask') }</h3>
                        <p>{ __('BlockHpTop.askDesc') }</p>
                        <a href="/servizio-clienti.html">{ __('BlockHpTop.contactUs') }</a>
                    </div>
                    <div block="StaticHomePage" elem="RightSecond">
                        <h3>{ __('BlockHpTop.titleTwo') }</h3>
                        <p>{ __('BlockHpTop.askSignUp') }</p>
                        <a href="#">{ __('BlockHpTop.signUp') }</a>
                    </div>
                </div>
            </div>
        );
    }

    renderTagA(elem: any): ReactElement {
        const url = (this.currentEnv === 'stg') ? elem.url : elem.urlProd;

        return (
            <a href={ url } rel="noopener noreferrer">
                <img src={ elem.image } alt={ elem.alt } />
            </a>
        );
    }

    renderTagASwiper(elem: any): ReactElement {
        const url = (this.currentEnv === 'stg') ? elem.url : elem.urlProd;

        return (
            <SwiperSlide>
                <a block="StaticHomePage" elem="ItemSlider" href={ url } rel="noopener noreferrer">
                    <img src={ elem.image } alt={ elem.alt } />
                </a>
            </SwiperSlide>
        );
    }

    blockHomepageMarche(): ReactElement {
        return (
            <>
                <div block="StaticHomePage" elem="ContainerMarche">
                    <div block="StaticHomePage" elem="ContainerRight">
                        <div block="StaticHomePage" elem="TextBox">
                            <h1>{ __('BlockHpMarche.title') }</h1>
                            <p>{ __('BlockHpMarche.desc') }</p>
                        </div>
                    </div>
                    <div block="StaticHomePage" elem="ImageGrid">
                        { marchiTop.map((elem) => this.renderTagA(elem)) }
                    </div>
                </div>
                <div block="StaticHomePage" elem="ImageList">
                    <Swiper
                      modules={ [Navigation, Pagination, Scrollbar, A11y] }
                      spaceBetween={ 30 }
                      slidesPerView={ 9 }
                      navigation
                      onSlideChange={ () => console.log('slide change') }
                    >
                        { marchiBottom.map((elem) => this.renderTagASwiper(elem)) }
                    </Swiper>
                </div>
            </>
        );
    }

    blockHomepagePromo(): ReactElement {
        return (
            <section block="StaticHomePage" elem="PromoSection">
                <img src={ `${baseImageUrl}moto2.png` } alt="" />
                <div block="StaticHomePage" elem="PromoText">
                    <div block="StaticHomePage" elem="PromoLabel">{ __('BlockHpPromo.subTitle') }</div>
                    <h2>{ __('BlockHpPromo.title') }</h2>
                    <p>{ __('BlockHpPromo.desc') }</p>
                </div>
            </section>
        );
    }

    blockHomepageOfferte(): ReactElement {
        const urlTprfactory = getUrlToSearch('794248', '80', this.currentEnv);

        return (
            <div block="StaticHomePage" elem="Container">
                <div block="StaticHomePage" elem="Card">
                    <img src={ `${baseImageUrl}homepage-promo1.png` } alt="" />
                    <div block="StaticHomePage" elem="CardText">
                        <img src={ `${baseImageUrl}flag.png` } alt="" />
                        <h3>{ __('BlockHpOfferte.titleBoxOne') }</h3>
                        <a href={ urlTprfactory } rel="noopener noreferrer">
                            <button>{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
                <div block="StaticHomePage" elem="Card">
                    <img src={ `${baseImageUrl}homepage-promo2.png` } alt="" />
                    <div block="StaticHomePage" elem="CardText">
                        <img src={ `${baseImageUrl}flag.png` } alt="" />
                        <p>{ __('BlockHpOfferte.subTitleBoxTwo') }</p>
                        <h3>{ __('BlockHpOfferte.titleBoxTwo') }</h3>
                        <a href="/offerte-settimana.html" rel="noopener noreferrer">
                            <button>{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    blockHomepageProductPromo(): ReactElement {
        const urlMph = getUrlToSearch('794221', '53', this.currentEnv);

        return (
            <div block="StaticHomePage" elem="ContainerSpecialPromo">
                <div block="StaticHomePage" elem="PromoBanner">
                    <h1>{ __('BlockHpProductPromo.title') }</h1>
                    <p>{ __('BlockHpProductPromo.desc') }</p>
                    <a
                      href={ urlMph }
                      rel="noopener noreferrer"
                      block="StaticHomePage"
                      elem="Button"
                    >
                        { __('BlockHpTop.showProduct') }
                    </a>
                        <div block="StaticHomePage" elem="Swoosh" />
                </div>
                <div block="StaticHomePage" elem="CarouselProduct">
                    <CmsBlock identifier="homepage-widget-products" />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="StaticHomePage">
                <div block="CmsPage-Wrapper CmsPage-Wrapper_page_width_default">
                    { this.blockHomepageTop() }
                    { this.blockHomepageMarche() }
                    <CmsBlock identifier="homepage-prodotti-primo-piano" />
                    { this.blockHomepagePromo() }
                    { this.blockHomepageProductPromo() }
                    { this.blockHomepageOfferte() }
                </div>
            </div>
        );
    }
}

export default StaticHomePageComponent;
