/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import TextPlaceholder from '@scandipwa/scandipwa/src/component/TextPlaceholder';
import { PureComponent } from 'react';

import If from 'Component/If';
import Image from 'Component/Image';
import Link from 'Component/Link';
import { ProductType } from 'Component/Product/Product.config';
import ProductPrice from 'Component/ProductPrice';
import SidePopup from 'Component/SidePopup';
import { CART_URL } from 'Route/CartPage/CartPage.config';
import { ReactElement } from 'Type/Common.type';
import { getPrice } from 'Util/Product/Extract';
import { ProductQuantity } from 'Util/Product/Product.type';

import { ADD_TO_CART_POPUP_ID } from './AddToCartPopup.config';
import { AddToCartPopupComponentProps } from './AddToCartPopup.type';

import './AddToCartPopup.style';

/** @namespace Pwa/Component/AddToCartPopup/Component */
export class AddToCartPopupComponent extends PureComponent<AddToCartPopupComponentProps> {
    clickOutside: () => void = (): void => {
        const closeBtn: HTMLElement = document.querySelector('.Popup-CloseBtn') as HTMLElement;
        closeBtn?.click();
    };

    preventRedirect: (e: any) => void = (e): void => {
        e.preventDefault();
    };

    renderTitle(): ReactElement {
        return (
      <div block="Title">
        <p>{ __('Cart.added') }</p>
      </div>
        );
    }

    renderQuantity(quantity: ProductQuantity): ReactElement {
        if (typeof quantity === 'number') {
            return <b>{ quantity }</b>;
        }

        return (
      <div>
        { Object.entries(quantity).map(([key, value]) => (
          <div key={ key }>
            <span>{ `Product ID: ${key}` }</span>
            <b>{ `Quantity: ${value}` }</b>
          </div>
        )) }
      </div>
        );
    }

    renderCard(): ReactElement {
        const { product, quantity } = this.props;

        const {
            product: {
                price_range: priceRange = {},
                type_id: type = '',
                dynamic_price: dynamicPrice,
                price_tiers: priceTiers,
                attributes: { sub_family: { attribute_options = {}, attribute_value = '' } = {} } = {},
            },
        } = this.props;

        const subFamilyLabel = attribute_options?.[attribute_value]?.label;

        return (
          <div block="AddToCartPopup" elem="Card">
            <Image src={ product.image?.url } alt="" />
            <p block="brand">{ subFamilyLabel }</p>
            <p block="name">{ product.name }</p>
            <If condition={ !!product.sku && product.type_id !== 'configurable' }>
              <p
                block="CartItem"
                elem="Sku"
              >
                <b>{ __('Cart.code') }</b>
                { ' ' }
                <TextPlaceholder content={ product.sku } />
              </p>
            </If>
            <p block="qty">
              <b>{ __('Cart.qty:') }</b>
              { ' ' }
              { this.renderQuantity(quantity) }
            </p>
            <ProductPrice
              price={ getPrice(priceRange, dynamicPrice, {}, type as ProductType) }
              priceType={ type as ProductType }
              tierPrices={ priceTiers }
            />
          </div>
        );
    }

    renderButtons(): ReactElement {
        return (
      <div
        block="BottomBox"
        onClick={ this.preventRedirect }
      >
        <Link
          block="AddToCartPopup"
          elem="CartButton"
          mix={ { block: 'Button', mods: { isHollow: true } } }
          onClick={ this.clickOutside }
          to={ CART_URL }
        >
          { __('View cart') }
        </Link>
        <button
          block="AddToCartPopup"
          elem="ContinueButton"
          mix={ { block: 'Button' } }
          onClick={ this.clickOutside }
        >
          { __('Cart.goOn') }
        </button>
      </div>
        );
    }

    render(): ReactElement {
        const { product: { sku } } = this.props;

        return (
      <SidePopup
        id={ `${ADD_TO_CART_POPUP_ID}_${sku}` }
        mix={ { block: 'AddToCartPopup' } }
      >
        { this.renderTitle() }
        <div block="Popup" elem="Body">
          { this.renderCard() }
          { this.renderButtons() }
        </div>
      </SidePopup>
        );
    }
}

export default AddToCartPopupComponent;
